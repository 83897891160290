import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Medications, StatusBadges, PharmaNetLoginDialog } from '../../components';
import { isPatient, isDoctor, formatDate, isPrivatePay, env} from '../../_helpers';
import { history } from '../../_helpers';
import { prescriptionAction, paymentAction, alertAction } from '../../_actions';
import { teleplanService } from "../../_services/tele-plan.service";
import { prescriptionService } from '../../_services/prescription.service';


class Prescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
               prescription:null,
        };
    }

    async componentDidMount() {
        const { dispatch, match } = this.props;
        const prescriptionId = match.params.id;

      dispatch(prescriptionAction.getById(prescriptionId));
      try{
        const res = await  prescriptionService.getById(prescriptionId);
        this.setState({
            prescription: res.data,
        })
      } catch(err){
          console.log(err);
      }
      
      
    }

    async isEligible() {
        if (isPrivatePay()) return false;
        // if (env() != "prod") return true;
        const { prescription } = this.props;
        const { person } = prescription;
        if (person.HealthDocuments.length == 0) return false;

        const eligibility = (await teleplanService.eligibility(person.HealthDocuments[0].DocumentNumber, person.DateOfBirth, person._id)).data;
        return eligibility.status == "valid" || eligibility.status == "pending";
    }

    handlePay = async() => {
        const { dispatch, prescription } = this.props;
        const isEligible = await this.isEligible();
        dispatch(paymentAction.checkout(prescription,isEligible));
    };

    renderPayBtn() {
        // const { prescription } = this.props;
        const { prescription } = this.state;
        if (isPatient() && prescription != null && prescription.statuses[0].text === 'Created') {
            return (
                <button className="btn btn-success btn-lg mr-4" onClick={this.handlePay}>Pay Now</button>
            );
        }

        return '';
    }

    renderContent() {
        const { prescription } = this.props;

        if (prescription) {
            const { person } = prescription;

            return (
                <div className="content">
                    <div className="left-panel">
                        <div className="row m-0">
                            <div className='medications'>
                                <div className="title">Selected Medications</div>
                                <Medications items={prescription.medications}></Medications>
                            </div>
                            <div className='patient-info'>
                                <div>
                                    <div className="title">Personal Information</div>
                                    <div className="row mb-2">
                                        <div className="col-xl-3 font-weight-500">Date of Birth: </div>
                                        <div className="col-xl-9">{formatDate(person.DateOfBirth)}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-xl-3 font-weight-500">Address: </div>
                                        <div className="col-xl-9">
                                            <div>{person.Address} </div>
                                            <div>{person.City}, {person.Province}, {person.PostalCode}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-xl-3 font-weight-500">Phone Number: </div>
                                        <div className="col-xl-9">{person.BestPhoneNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-panel">
                        <div className="status">
                            <StatusBadges statusList={prescription.statuses} prescription={prescription} />
                        </div>
                        <div className="text-right">
                            {this.renderPayBtn()}
                        </div>
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }

    renderTitle() {
        const { prescription } = this.props;

        if (prescription) {
            return (
                <h1 className="h2">
                    Prescription
                    <span className="prescription-id"> ({prescription._id})</span>
                </h1>
            );
        } else {
            return <h1 className="h2">Prescription</h1>;
        }
    }

    renderPharmaNetButton() {
        const { prescription } = this.props;
        if ( prescription && isDoctor() ) {
            const { person } = prescription;

            let phn = null;
            for (let healthDoc of person.HealthDocuments ) {
                if (healthDoc.Type == 'BC Card' || healthDoc.Type == 'Canadian Health Card') {
                    phn = healthDoc.DocumentNumber;
                }
            }

            if ( phn ) {
                return <PharmaNetLoginDialog phn={phn}/>;
            }
        }
        return '';
    }

    handlePhnError() {
        const { dispatch } = this.props;
        dispatch(alertAction.error('PHN not found', 'the patient doesn\'t have a BC Card or Canadian Health Card uploaded.'));
    }

    render() {
        return (
            <div className="prescription-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    {this.renderTitle()}
                </div>
                <div>
                    {this.renderContent()}
                </div>
                <div>
                    {this.renderPharmaNetButton()}
                </div>
                <div>
                    <button className="btn btn-outline-secondary btn-lg my-4" onClick={() => history.push('/prescriptions')}>Back</button>
                </div>
            </div>
        );
    }
}


Prescription.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object,
    prescription: PropTypes.object
};


function mapStateToProps(state) {
    const { prescriptions, prescription } = state.prescription;

    return {
        prescriptions,
        prescription
    };
}

const temp = connect(mapStateToProps)(Prescription);

export { temp as Prescription };